import React from "react";

import { connect } from "react-redux";
import { downloadfile } from "../../reducers/fotoboek/fotos/actions.js";
class JaarplanPage extends React.Component {
  download(file, e) {
    var data = {
      filename: file[0],
      file: file[1],
    };
    this.props.downloadfile(data).then(function (result) {
      console.log(result);
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-9">
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Jaarplan</h3>
            </div>
            <div className="box-body">
              <dt>
                Op deze pagina vindt u de jaarplannen per jaar van Stichting
                Promotie Gemeente Epe
              </dt>
              <p className="text-green">
                De plannen worden als download aangeboden. De jaarplannen worden
                jaarlijks gepubliceerd.
              </p>
            </div>
          </div>
          <ul className="timeline">
          <li className="time-label">
              <span className="bg-red">2023</span>
            </li>
            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaaroverzicht</h3>
                <div class="timeline-body">
                We publiceren elk jaar het jaaroverzicht van SPGE. Via de onderstaande link vindt u het overzicht van 2023.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaaroverzicht 2023 SPGE.pdf",
                      "Jaaroverzicht 2023",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaaroverzicht 2023
                  </button>
                </div>
              </div>
            </li>
       
          <li className="time-label">
              <span className="bg-red">2022</span>
            </li>
            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Infografic 2022</h3>
                <div class="timeline-body">
              Dit jaar hebben we een infografic gemaakt van het jaaroverzicht. In de onderstaande link vindt u de infografic van 2022.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaarverslag-SPGE-2022-A4 infographic-A4-staand-v01.pdf",
                      "Jaarverslag-SPGE-2022",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Infografic 2022
                  </button>
                </div>
              </div>
            </li>
            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaaroverzicht</h3>
                <div class="timeline-body">
                We publiceren elk jaar het jaaroverzicht van SPGE. Via de onderstaande link vindt u het overzicht van 2022.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaaroverzicht 2022 SPGE-gecomprimeerd.pdf",
                      "Jaaroverzicht 2022",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaaroverzicht 2022
                  </button>
                </div>
              </div>
            </li>
          <li className="time-label">
              <span className="bg-red">2021</span>
            </li>

            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaaroverzicht</h3>
                <div class="timeline-body">
                  Jaarlijks publiceren we het jaaroverzicht van SPGE. In de onderstaande link het jaaroverzicht van 2021
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaaroverzicht 2021 SPGE.pdf",
                      "Jaaroverzicht 2021",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaaroverzicht 2021
                  </button>
                </div>
              </div>
            </li>
            <li className="time-label">
              <span className="bg-red">Meerjarenplan</span>
            </li>

            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Meerjarenplan 2022 -2025</h3>
                <div class="timeline-body">
                  Voor u ligt het meerjarenplan 2022-2025 van Stichting Promotie
                  Gemeente Epe (SPGE). Dit meerjarenplan is in een intensief en
                  inspirerend traject tussen SPGE, gemeente Epe,
                  recreatieondernemers en De Plekkenmakers tot stand gekomen en
                  geldt als vervolg op het in 2017 ontwikkelde en door SPGE
                  geïmplementeerde toeristisch profiel 100% Wildgarantie.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Meerjarenplan De Gemeente Epe 2022-2025.pdf",
                      "Meerjarenplan De Gemeente Epe 2022-2025",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Meerjarenplan 2022 -2025
                  </button>
                </div>
              </div>
            </li>
            <li className="time-label">
              <span className="bg-red">2020</span>
            </li>

            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaaroverzicht</h3>
                <div class="timeline-body">
                  2020 was in alle opzichten een heel bijzonder jaar. In dit
                  jaarverslag staan de activiteiten die we in 2020 hebben
                  ondernomen.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaaroverzicht 2020 SPGE.pdf",
                      "Jaaroverzicht 2020",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaaroverzicht 2020
                  </button>
                </div>
              </div>
            </li>
            <li className="time-label">
              <span className="bg-red">2019</span>
            </li>

            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaaroverzicht</h3>
                <div class="timeline-body">
                  In dit document vind je het jaaroverzicht van SPGE van 2019.
                  In het document staat beschreven welke projecten gerealiseerd
                  zijn.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaaroverzicht 2019 SPGE.pdf",
                      "Jaaroverzicht 2019",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaaroverzicht 2019
                  </button>
                </div>
              </div>
            </li>
            <li className="time-label">
              <span className="bg-red">2018</span>
            </li>

            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaaroverzicht</h3>
                <div class="timeline-body">
                  In dit document vind je het jaaroverzicht van SPGE van 2018.
                  In het document staat beschreven welke projecten gerealiseerd
                  zijn.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "Jaaroverzicht 2018 SPGE.pdf",
                      "Jaarploverzicht 2018",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaaroverzicht 2018
                  </button>
                </div>
              </div>
            </li>

            <li>
              <i class="fa fa-book bg-blue" />
              <div class="timeline-item">
                <h3 class="timeline-header">Jaarplan</h3>
                <div class="timeline-body">
                  In dit document vind je het jaarplan 2018. In de hoofdstukken
                  kan je lezen welke projecte er gerealiseerd gaan worden.
                </div>
                <div class="timeline-footer">
                  <button
                    onClick={this.download.bind(this, [
                      "jaarplan 2018-publicatie.pdf",
                      "Jaarplan 2018",
                    ])}
                    type="button"
                    className="btn  btn-primary btn-sm"
                  >
                    Jaarplan 2018
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {/* ./col */}
        <div className="col-md-3">
          <div className="box box-solid">
            {/* /.box-header */}
            <div className="box-body">
              <img src="/100wild/1x/Middel 1.png" alt="Foto Logo" />
            </div>
            {/* /.box-body */}
          </div>
          {/* /.box */}
        </div>
        {/* ./col */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return true;
}

export default connect(mapStateToProps, { downloadfile })(JaarplanPage);
